import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = props => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
        contactJson {
          phone
          text
        }
    }
   `);
  return props.showButton && (
    <a href={`tel:${data.contactJson.phone}`} className="call-button">
      {data.contactJson.phone && (
        <div className="call-phone">
          <strong>Pozovite:</strong>
          {' '}
          { data.contactJson.text }
        </div>
      )}
    </a>
  );
};

export default Call;
